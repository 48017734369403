import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center h-screen w-full bg-neutral-light" }

import { AuthorizationCodeRequest } from "@/models/IdentityServer";
import { onMounted } from "vue";
import { START_LOCATION, useRoute, useRouter } from "vue-router";
import AccountModule from "@/store/modules/Account";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { IdentityAPI } from "@/services/identity";
import { IDENTITY_GRANT_TYPE } from "@ems/constants/identity_grant_type";
import {
  getCodeVerifier,
  getEnv,
  logoutIdentity,
  setAccessToken,
  setIdToken,
  setRefreshToken,
  setRole,
  setTenantId,
  setTenantIsActive,
  setTenantStatus,
} from "@/utils/storage";
import jwtDecode from "jwt-decode";
import {
  ICreateUserIdentityRequest,
  ICreateUserIdentityResponse,
} from "@/models/User";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import { ElMessage } from "element-plus";
import { OPS_ADMIN } from "@ems/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'IdentityCallback',
  setup(__props) {

const router = useRouter();
const route = useRoute();

const getTokens = async () => {
  const env = getEnv();
  const code = route.query.code?.toString() ?? "";
  const clientId = env?.VUE_APP_IDENTITY_CLIENT_ID;
  const redirectUrl = env?.VUE_APP_IDENTITY_REDIRECT_URL;
  const codeVerifier = getCodeVerifier() ?? "";

  const req: AuthorizationCodeRequest = {
    grantType: IDENTITY_GRANT_TYPE.AUTHORIZATION_CODE,
    code,
    clientId,
    redirectUrl,
    codeVerifier,
  };

  try {
    const serviceIdentityAPI = new IdentityAPI();
    const { data, error } = await serviceIdentityAPI.authorizationCode(req);
    if (data) {
      // Store the access token and refresh token in the browser's local storage
      setAccessToken(data?.access_token);
      setIdToken(data?.id_token);
      setRefreshToken(data?.refresh_token);

      //Register user in DB and Redirect the user back to the main application or another page
      const decodetoken: any = jwtDecode(data?.access_token);
      const userInfo: ICreateUserIdentityRequest = {
        Username: decodetoken.username,
        Email: decodetoken.email,
        UserId: decodetoken.sub,
        Role: decodetoken.role ?? "",
        RefreshToken: data?.id_token,
      };
      const response: ICreateUserIdentityResponse =
        await AccountModule.createUserIdentity(userInfo);
      let redirectPath = "";

      if (
        response != null &&
        response.User &&
        response.User.Roles[0] === OPS_ADMIN
      ) {
        setTenantIsActive(true);
        setRole(response.User.Roles[0]);
        setTenantStatus(STATUS_TENANT.Approved); //TODO: refactor the response to separate admin

        redirectPath = "/admin";
        await AccountModule.loginIdentity();

        return router.replace(`${redirectPath}`);
      } else if (response != null) {
        if (response.User && response.User.Tenants) {
          const tenantId = response.User.Tenants[0].Id;
          const isActive = response.User.Tenants[0].IsActive;
          const status = response.User.Tenants[0].Status;
          const role = response.User.Roles[0];

          setTenantId(tenantId ?? "");

          setTenantIsActive(isActive);
          setTenantStatus(status);
          setRole(role);

          if (!response.IsExisting) {
            redirectPath = "/tenant/create";
          } else {
            if (!response.User.Tenants[0].IsActive) {
              redirectPath = "/tenant/create";
            } else {
              if (response.User.Tenants[0].Status != STATUS_TENANT.Approved) {
                redirectPath = "/tenant/waiting-approval";
              } else {
                redirectPath = "/";
              }
            }
          }

          await AccountModule.loginIdentity(response.User.Tenants[0].Id);
          return router.replace(`${redirectPath}`);
        }
      } else {
        logoutIdentity();
        ElMessage.error("Authorization Code is invalid");
      }
    }
  } catch (error) {
    console.log(error);
    logoutIdentity();
    ElMessage.error("Authorization Code is invalid");
  }
};

onMounted(async () => {
  await getTokens();
});

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { class: "w-full max-w-md p-6 text-center shadow-lg bg-white" }, {
      default: _withCtx(() => [
        _createVNode(BaseSpinner),
        _cache[0] || (_cache[0] = _createElementVNode("h6", { class: "text-xl font-semibold text-gray-600" }, "Processing login...", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-gray-500 mt-2" }, " Please wait while we verify your identity. ", -1))
      ]),
      _: 1
    })
  ]))
}
}

})